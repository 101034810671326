import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getAllProjects(allColumnsReq = true) {
  let statusArr = ["Active", "Inactive", "Closed"];
  let addProjects = [];
  let sortedProjects = [];
  let resArr = [];
  for (let dt of statusArr) {
    const projectresult = getStatusWiseProjects(dt, allColumnsReq);
    resArr.push(projectresult);
  }
  for (let res of resArr) {
    let projectresult = await Promise.resolve(res);
    if (projectresult?.length > 0)
      addProjects = [...addProjects, ...projectresult];
  }
  for (let dt of statusArr) {
    const projectresult = addProjects.filter((item) => item.DeleteIndication === dt);
    sortedProjects = [...sortedProjects, ...projectresult];
  }
  return sortedProjects;
}

export async function getProjectTeamData(projCode) {
  let reqBody = { projCode }
  const response = await axios
    .post(TRANS_URL + `/projectmaster/getProjectTeamData`, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectActiveTeamData(projCode) {
  let reqBody = { projCode }
  const response = await axios
    .post(TRANS_URL + `/projectmaster/getProjectActiveTeamData`, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectServiceEqui(projCode) {
  let reqBody = { projCode }
  const response = await axios
    .post(TRANS_URL + `/projectmaster/getProjectServiceData`, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectActiveServiceEqui(projCode) {
  let reqBody = { projCode }
  const response = await axios
    .post(TRANS_URL + `/projectmaster/getProjectActiveServiceData`, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectDocuments(projCode) {
  let reqBody = { projCode }
  const response = await axios
    .post(TRANS_URL + `/projectmaster/getProjectDocuments`, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectData(projCode) {
  let url = `${TRANS_URL}/projectmaster/projectOverallData`;
  const response = await axios
    .post(url, projCode)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export const AddProjectDetails = async (Project) => {
  let url = `${TRANS_URL}/projectmaster/addProject`;
  const response = await axios
    .post(url, Project)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};


export const AddProjectTeam = async (team) => {
  let counter = 0;
  let array = [];
  let tmpOutputArray = [];
  for (let item of team) {
    let tmpRes = AddTeamResponsive(item);
    array.push({ result: tmpRes });
    counter++;
    if (counter === 5) {
      for (let dt of array) {
        let tmpResult = await Promise.resolve(dt.result);
        tmpOutputArray.push({ output: tmpResult });
      }
      counter = 0;
      array = [];
    }
  }
  for (let dt of array) {
    let tmpResult = await Promise.resolve(dt.result);
    console.log(tmpResult)
    tmpOutputArray.push({ output: tmpResult });
  }
  if(tmpOutputArray?.length>0){
    return tmpOutputArray[0].output;
  }
  else{
    return { data: { message: "Unable to find request", status: false } };
  }
};

export const AddServiceEquipment = async (service) => {
  let count = 0;
  let resultArray = [];
  let tmpOutputArr = [];
  for (let element of service) {
    let tmpRes = AddSrvcEqpmntResponsive(element);
    resultArray.push({ result: tmpRes });
    count++;
    if (count === 5) {
      for (let dt of resultArray) {
        let tmpResult = await Promise.resolve(dt.result);
        tmpOutputArr.push({ output: tmpResult });
      }
      count = 0;
      resultArray = [];
    }
  }
  for (let dt of resultArray) {
    let tmpResult = await Promise.resolve(dt.result);
    console.log(tmpResult)
    tmpOutputArr.push({ output: tmpResult });
  }
  if(tmpOutputArr?.length>0){
    return tmpOutputArr[0].output;
  }
  else{
    return { data: { message: "Unable to find request", status: false } };
  }
};

export const AddSrvcEqpmntResponsive = async (serviceDetails) => {
  let url = `${TRANS_URL}/projectmaster/addSrvcEqpmntResponsive`;
  const response = await axios
    .post(url, serviceDetails)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export const AddTeamResponsive = async (serviceDetails) => {
  let url = `${TRANS_URL}/projectmaster/addTeamResponsive`;
  const response = await axios
    .post(url, serviceDetails)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export const AddProjectOrder = async (orderData) => {
  let count = 0;
  let resultArr = [];
  let outPutArr = [];
  let signedURLArr = [];
  for (let item of orderData) {
    let tmpRes = AddOrderResponsive(item);
    resultArr.push({ result: tmpRes });
    count++;
    debugger;
    if (count === 5) {
      for (let dt of resultArr) {
        let tmpResult = await Promise.resolve(dt.result);
        outPutArr.push({ output: tmpResult });
        if (tmpResult.signedURL) {
          signedURLArr.push(tmpResult.signedURL);
        }
      }
      count = 0;
      resultArr = [];
    }
  }
  for (let dt of resultArr) {
    debugger;
    let tmpResult = await Promise.resolve(dt.result);
    outPutArr.push({ output: tmpResult });
    if (tmpResult.signedURL) {
      signedURLArr.push(tmpResult.signedURL);
    }
  }
  if (outPutArr?.length > 0) {
    let res = { message: outPutArr[0].output.message, status: outPutArr[0].output.status };
    return { ...res, ...{ signedURL: signedURLArr } };
  }
  else {
    return { data: { message: "Unable to find request", status: false } };
  }
};

export const AddOrderResponsive = async (orderDetails) => {
  let url = `${TRANS_URL}/projectmaster/addOrderResponsive`;
  const response = await axios
    .post(url, orderDetails)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export const UpdateProjectDetails = async (Project) => {
  let url = `${TRANS_URL}/projectmaster/updateprojectdetails`;
  const response = await axios
    .post(url, Project)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export const UpdateSrvcEqpmntResponsive = async (Project) => {
  let url = `${TRANS_URL}/projectmaster/UpdateSrvcEqpmntResponsive`;
  const response = await axios
    .post(url, Project)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export async function deleteProject(project_id) {
  let url = `${TRANS_URL}/projectmaster/deleteProject/${project_id}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}

export async function DeleteProjectTeam(projteamobj) {
  let url = `${TRANS_URL}/projectmaster/deleteProjectTeam`;
  const resp = await axios
    .post(url, projteamobj)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}


export async function inactiveProjectTeam(projteamobj) {
  let url = `${TRANS_URL}/projectmaster/inactiveProjectTeam`;
  const resp = await axios
    .post(url, projteamobj)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}

export async function inactiveProjectService(projserviceobj) {
  let url = `${TRANS_URL}/projectmaster/inactiveProjectService`;
  const resp = await axios
    .post(url, projserviceobj)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}

export async function DeleteProjectService(serviceObj) {
  let url = `${TRANS_URL}/projectmaster/deleteServiceEqpmnt`;
  const resp = await axios
    .post(url, serviceObj)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}

export async function DeleteProjectOrder(orderDocument) {
  let url = `${TRANS_URL}/projectmaster/deleteOrderDocument`;
  const resp = await axios
    .post(url, orderDocument)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}

export async function getProjectsByEquipment(equipcode) {
  let url = `${TRANS_URL}/projectmaster/getProjectsByEquipment`;
  let reqBody = { equipcode }
  const resp = await axios
    .post(url, reqBody)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return resp.data;
}


export async function updateProjectInactive(projObj) {
  let url = `${TRANS_URL}/projectmaster/updateProjectInactive`;
  const resp = await axios
    .post(url, projObj)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: error.message, status: false } };
    });
  return resp.data;
}


// export async function getActiveProjects(userData) {
//   let url;
//   if (userData.dataControl === "All Projects Data") {
//     url = `${TRANS_URL}/projectmaster/getactiveprojects`;
//   }
//   else {
//     url = `${TRANS_URL}/projectmaster/getactiveprojectsbyuser/${encodeURIComponent(userData.userId)}`;
//   }
//   const response = await axios
//     .get(url)
//     .then(function (resp) {
//       return resp;
//     })
//     .catch(function (error) {
//       console.log("error => ", error);
//       return { data: [] };
//     });
//   return response.data;
// }


export async function getStatusWiseProjects(projStatus, allColumnsReq = true) {
  let reqBody = { projStatus, allColumnsReq }
  let url = `${TRANS_URL}/projectmaster/getstatuswiseprojects`;

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getStatusWiseProjectsByUser(userId, projStatus, allColumnsReq = true) {
  let reqBody = { projStatus, userId, allColumnsReq };
  let url = `${TRANS_URL}/projectmaster/getstatuswiseprojectsbyuser`;

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectsByUser(userId) {
  let url = `${TRANS_URL}/projectmaster/getprojectsbyuser`;
  let reqBody = { userId }

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectsByLocation(locationCode) {
  let url = `${TRANS_URL}/projectmaster/getprojectsbylocation`;
  let reqBody = { locationCode }

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectsByCompany(compCode) {
  let url = `${TRANS_URL}/projectmaster/getprojectsbycompany`;
  let reqBody = { compCode }

  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getProjectServiceByEquipTag(projCodePK, projCodeSK) {
  let url = `${TRANS_URL}/projectmaster/getProjectServiceByEquipTag`;
  let reqBody = { "projCodePK": projCodePK, "projCodeSK": projCodeSK };
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================get equipments tag data by project,equipment and service==========================
export async function getEquipmentTagDataForEquipment(projequipObj) {
  const response = await axios
    .post(TRANS_URL + "/projectmaster/gettagsforequipment", projequipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//========================= get project master data by project code =================================
export async function getprojectdatabyprojectcode(projCodeObj) {
  let url = `${TRANS_URL}/projectmaster/getprojectdatabyprojectcode`;
  const response = await axios
    .post(url, projCodeObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getUserWiseAllProjects(userDetails, allColumnsReq = true) {
  let statusArr = ["Active", "Inactive", "Closed"];
  let addProjects = [];
  let sortedProjects = [];
  if (userDetails.dataControl === "All Projects Data") {
    let resArr = [];
    for (let dt of statusArr) {
      const projectresult = getStatusWiseProjects(dt, allColumnsReq);
      resArr.push(projectresult);
    }
    for (let res of resArr) {
      let projectresult = await Promise.resolve(res);
      if (projectresult?.length > 0)
        addProjects = [...addProjects, ...projectresult];
    }
  }
  else {
    const projectresult = await getStatusWiseProjectsByUser(userDetails.userId, "Active", allColumnsReq);
    addProjects = [...addProjects, ...projectresult];
  }
  for (let dt of statusArr) {
    const projectresult = addProjects.filter((item) => item.DeleteIndication === dt);
    sortedProjects = [...sortedProjects, ...projectresult];
  }
  return sortedProjects;
}